'use client';

import { useContext } from 'react';
import { useSearchParams } from 'next/navigation'
import cn from 'classnames';

import { Col, Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import Icon from 'components/Icon';
import Image from 'components/Image';
import PropertyRate from 'components/PropertyRate';
import PriceConverter from 'components/PriceConverter';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { isCloudinaryImage } from 'utils';
import averageNightlyRate from 'utils/averageNightlyRate';
import { FORCE_THUMBNAIL_WORKAROUND } from 'constants/featureToggleNames';
import { Link } from 'i18n/routing';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { usePropertyTypes } from 'hooks/useConfigSettings';
import useTotalPrice from 'hooks/useTotalPrice';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    width: '100%',
    maxWidth: 1095,
    flex: '0 0 214px',
    background: '#fff',
    border: '1px solid #F5F5F5',
    boxShadow: '0px 1px 2px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 20,
    position: 'relative',
    [create('xs')]: {
      flex: '0 0 auto',
    },
    [create('xl')]: {
      flex: ({ shouldShowMapOnSearch }) => (shouldShowMapOnSearch ? '0 0 230px' : '0 0 917px'),
    },
  },
  rowWrapper: {
    display: 'flex',
    flex: 1,
    [create('xs')]: {
      flexDirection: 'column',
      height: 'auto',
      padding: 10,
    },
    [create('md')]: {
      padding: '10px 20px',
    },
    [create('xl')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 15,
    },
  },
  propertyFooter: {
    display: 'flex',
    marginTop: 'auto',
    '& > div': {
      width: '100%',
    },
    [create('xs')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 10,
    },
    [create('lg')]: {
      marginTop: 'auto',
    },
    [create('xl')]: {
      flexDirection: 'column',
      alignItems: 'end',
    },
  },
  propertyImage: {
    width: '100%',
    height: '100%',
    [create('xs')]: {
      width: '100%',
      height: 240,
    },
    [create('xl')]: {
      width: 225,
      height: 230,
    },
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  propertyWrapper: {
    height: '100%',
    display: 'flex',
    [create('xs')]: {
      flexDirection: 'column',
    },
    [create('xl')]: {
      flexDirection: 'row',
    },
  },
  descriptionText: {
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    maxWidth: 424,
    marginTop: 10,
    [create('xs')]: {
      display: 'none',
    },
    [create('xl')]: {
      display: '-webkit-box',
    },
  },
  propertyData: {
    width: '100%',
    [create('xs')]: {
      marginRight: 0,
      '& .pl-2': {
        paddingLeft: '0 !important',
      },
    },
    [create('sm')]: {
      marginRight: 15,
      '& .pl-2': {
        paddingLeft: '10px !important',
      },
    },
    [create('md')]: {
      marginRight: 35,
    },
  },
  titleText: {
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    [create('xl')]: {
      fontSize: 28,
    },
    [create('sm')]: {
      fontSize: 25,
    },
    [create('xs')]: {
      fontSize: 21,
    },
  },
  accomodates: {
    alignSelf: 'flex-start',
    '& > div[class^="TextField"]': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      [create('md')]: {
        flexDirection: 'column',
      },
      [create('xl')]: {
        flexDirection: 'row',
      },
      '&:not(:last-child)': {
        '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          height: 20,
          width: 2,
          background: '#D4D8E1',
          right: -10,
          top: 12,
          marginTop: -10,
          '@media(max-width: 1578px)': {
            display: 'none',
          },
        },
      },
    },
    [create('xs')]: {
      fontSize: 10,
      width: '100%',
      '& > div[class^="TextField"]': {
        '&:not(:last-child)': {
          marginRight: 15,
        },
      },
    },
    [create('md')]: {
      width: 'auto',
      '& > div[class^="TextField"]': {
        '&:not(:last-child)': {
          marginRight: 20,
        },
      },
    },
  },
  viewNow: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    padding: '5px!important',
    [create('xs')]: {
      width: '100%',
      marginLeft: 'auto',
    },
    [create('md')]: {
      width: 170,
    },
    [create('xl')]: {
      width: 161,
    },
    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
  },
  contentRow: {
    height: '100%',
    justifyContent: 'space-between',
    [create('xs')]: {
      flexDirection: 'column',
    },
    [create('xl')]: {
      flexDirection: 'row',
    },
  },
  colWrapper: {
    width: '100%',
  },
  titleWrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  recommendedTile: {
    padding: [4, 8],
    backgroundColor: '#E6F3FF',
    color: '#2A57EC',
    fontSize: 12,
    width: 190,
  },
}));

const PropertyRecommendedTile = ({ property }) => {
  const { recommendedTile } = useStyles();
  if (!property?.isRecommended) return null;

  const label = t('Recommended for your group');
  return <div className={recommendedTile}>{label}</div>;
};


const PropertyCard = ({ property, handlePropertyCardClick, index }) => {
  const searchParams = useSearchParams();
  const search = searchParams.toString();
  const { getPathWithQueryParams } = useGetPathToNavigate();
  const path = getPathWithQueryParams({ destinationPath: `/properties/${property?._id}`, search });
  const { allPropertyTypes: propertyTypes } = usePropertyTypes();
  const [, isForceThumbnal] = useFeatureToggle(FORCE_THUMBNAIL_WORKAROUND);
  const {
    displayOptions: { shouldShowMapOnSearch = false } = {},
  } = useContext(WebsiteSettingsContext);
  const {
    root,
    propertyImage,
    propertyWrapper,
    propertyFooter,
    descriptionText,
    propertyData,
    accomodates,
    viewNow,
    rowWrapper,
    titleText,
    contentRow,
    colWrapper,
    titleWrapper,
  } = useStyles({ shouldShowMapOnSearch });
  const {
    picture: { thumbnail } = {},
    pictures,
    title,
    publicDescription,
    propertyType,
    accommodates,
    bedrooms,
    bathrooms,
    prices: { basePrice, currency },
    reviews,
    nightlyRates,
    totalPrice,
  } = property;
  const { isTotalPriceEnabled, price } = useTotalPrice({
    beforeTaxesPrice: nightlyRates ? averageNightlyRate(nightlyRates) : basePrice,
    totalPrice,
  });
  const { country, city } = property?.address;
  const addressLabel = city && country ? `${city}, ${country}` : city || country;
  const propertyTypeLabel =
    Object.values(propertyTypes).find((item) => item.value.toLowerCase() === propertyType?.toLowerCase())?.label ||
    propertyType;
  const guestsLabel = t(
    `{ num, plural, =0 {# Guests} =1 {1 Guest} few {# Guests} many {# Guests} other {# Guests}}`,
    { num: accommodates || 0 }
  );
  const bedroomsLabel = t(
    '{ num, plural, =0 {# Bedrooms} =1 {1 Bedroom} few {# Bedrooms} many {# Bedrooms} other {# Bedrooms}}',
    { num: bedrooms || 0 }
  );

  const bathroomLabel = t(
    `{ num, plural, =0 {# Bathrooms} =1 {1 Bathroom} few {# Bathrooms} many {# Bathrooms} other {# Bathrooms}}`,
    { num: bathrooms || 0 }
  );

  return (
    <div data-property-row className={root}>
      <div className={propertyWrapper}>
        <div className={propertyImage}>
          <Link
            href={path}
            onClick={() => handlePropertyCardClick(property, index)}
          >
            {isForceThumbnal ? (
              <Image
                alt="alt"
                src={
                  isCloudinaryImage(pictures[0]?.original) ? pictures[0]?.original : pictures[0]?.thumbnail || thumbnail
                }
                h={240}
                isNewParser
              />
            ) : (
              <Image alt="alt" src={pictures[0]?.original || thumbnail} h={240} />
            )}
          </Link>
        </div>
        <div className={rowWrapper}>
          <Col className={colWrapper}>
            <div className={titleWrapper}>
              <TextField data-qa="property-title" className={titleText} variant="h1" color="input">
                {title}
              </TextField>
              <Col spacing={1}>
                <PropertyRecommendedTile property={property} />
                {reviews.total > 0 && <PropertyRate property={property} />}
              </Col>
            </div>
            <Row className={contentRow}>
              <Col className={propertyData}>
                <Row className="mt-2" align="center">
                  <Icon className="mr-1" icon="location" size={22} />
                  <TextField data-qa="property-address" style={{ fontSize: 12 }} color="secondary">
                    {addressLabel}
                  </TextField>
                </Row>
                <TextField data-qa="property-summary" className={descriptionText} color="secondary">
                  {publicDescription?.summary}
                </TextField>
                <Row align="center" className={cn(accomodates, 'mt-auto justify-content-between')}>
                  {propertyTypeLabel && (
                    <TextField color="secondary">
                      <Icon icon="hotel" size={25} />
                      <h3 className="inherit-all" data-qa="property-type">
                        {propertyTypeLabel}
                      </h3>
                    </TextField>
                  )}
                  <TextField color="secondary">
                    <Icon icon="guest" size={25} />
                    <h3 className="pl-2 inherit-all" data-qa="property-guests-label">
                      {guestsLabel}
                    </h3>
                  </TextField>
                  <TextField color="secondary">
                    <Icon icon="listing_beds" size={25} />
                    <h3 className="pl-2 inherit-all" data-qa="property-bedroom-label">
                      {bedroomsLabel}
                    </h3>
                  </TextField>
                  <TextField color="secondary">
                    <Icon icon="listing_bathroom" size={25} />
                    <h3 className="pl-2 inherit-all" data-qa="property-bathroom-label">
                      {bathroomLabel}
                    </h3>
                  </TextField>
                </Row>
              </Col>
              <div className={propertyFooter}>
                <div>
                  {price && currency && (
                    <>
                      {!isTotalPriceEnabled && <TextField color="secondary">{t('from')}</TextField>}
                      <Row align="center">
                        <TextField variant="h2" className="font-weight-normal">
                          <PriceConverter currency={currency} amount={price} />
                        </TextField>
                        <TextField className="ml-2" color="secondary">
                          {t('{isTotalPriceEnabled, select, true {Total} false {Per night} other {Per night}}', {
                            isTotalPriceEnabled,
                          })}
                        </TextField>
                      </Row>
                    </>
                  )}
                </div>
                {!isTotalPriceEnabled && (
                  <TextField variant="h8" color="secondary">
                    {t('Additional charges may apply')}
                  </TextField>
                )}
                <Link
                  data-qa="book-now"
                  href={path}
                  onClick={() => handlePropertyCardClick(property, index)}
                  className={cn(viewNow, 'mt-2 btn btn-colored')}
                >
                  {t('Book now')}
                </Link>
              </div>
            </Row>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
