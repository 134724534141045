'use client';

import qs from 'query-string';
import { useLocale } from 'next-intl';

import { useSearchParams } from 'next/navigation';


function usePureSearchValues() {
  const search = useSearchParams();
  const locale = useLocale();
  const {
    checkIn,
    checkOut,
    minOccupancy,
    city,
    country,
    currency,
    minPrice,
    maxPrice,
    propertyType,
    tags,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    rooms,
    sortBy,
    sortOrder,
  } = qs.parse(search);

  return {
    dates: { startDate: checkIn, endDate: checkOut },
    location: { city, country },
    guests: minOccupancy,
    rooms,
    currency,
    minPrice,
    maxPrice,
    propertyType,
    tags,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    lang: locale,
    sortBy,
    sortOrder,
  };
}

export default usePureSearchValues;